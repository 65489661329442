import { isDEVMODE, domStorage, viewStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

export default class PageTransitionDefault extends Transition {
  onEnter({ done }) {
    viewStorage.lastPage.remove()
    domStorage.body.classList.remove('--animating')
    pageIntro(1)
    done()
  }

  onLeave({ from, done }) {
    viewStorage.lastPage = from
    done()
  }
}
