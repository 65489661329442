import { isDEVMODE } from '../_globals'

export default class Woocommerce {

  constructor() {
    this.addEvents()
  }

  addEvents() {

    if (isDEVMODE) console.log('Init Woocommerce')

    $(document.body).on('adding_to_cart',  function(event, fragments, cart_hash) {
      document.body.classList.add('--adding-to-cart')
      console.log('adding_to_cart',fragments, cart_hash);
    })

    $( document.body ).on( 'added_to_cart', function(event, fragments, cart_hash) {
      document.body.classList.remove('--adding-to-cart')
      console.log('added_to_cart',fragments, cart_hash);
    });

  }
}
