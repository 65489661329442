import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Anchors from '../modules/anchors'

export default class PageFAQ extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      accordions : viewStorage.current.querySelectorAll('.faq__block .card'),
      links : viewStorage.current.querySelectorAll('.faq__container .link')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page FAQ`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { accordions } = this.DOM
    accordions.length > 0 && this.initAccordion()
  }

  initAccordion() {
    const { accordions } = this.DOM
    this.onToggleAccordion = this.onToggleAccordion.bind(this)
    this.faqNav = new Anchors(viewStorage.current.querySelector('.faq aside'))
    accordions.forEach(accordion => accordion.querySelector('h4').addEventListener('click', this.onToggleAccordion))
  }

  onToggleAccordion(e) {
    const { currentTarget } = e
    const accordionItem = currentTarget.parentNode
    accordionItem.classList.toggle('--active')
  }

  /* initLinks() {
    const { links } = this.DOM

    document.addEventListener('click', function (event) {
      if (event.target.matches('.faq__container .link')) {
        links.forEach(function (link) {
          link.classList.remove('--active');
        });

        event.target.classList.add('--active');
      }
    });
  } */

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { faqNav } = this
    const { accordions } = this.DOM
    faqNav && faqNav.destroy()
    accordions.length > 0 && accordions.forEach(accordion => accordion.querySelector('h4').removeEventListener('click', this.onToggleAccordion))
  }
}
