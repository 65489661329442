import { viewStorage } from '../_globals'
import gsap from 'gsap'

export default class TiltCards {
  constructor(container) {
    this.DOM = { container } 
    this.DOM.cards = this.DOM.container.querySelectorAll('.tilt-card')

    if (!this.DOM.cards.length)  return

    this.settings = { duration: 1, ease: 'expo.out' }
    this.tweensIn = []
    this.tweensOut = []

    this.init()
  }

  init() {
    const { cards } = this.DOM

    if (cards.length) cards.forEach(card => {
      gsap.set(card, { transformPerspective: 900, transformOrigin: 'center center -10' })

      card.addEventListener('mousemove', this.moveCard.bind(this))
      card.addEventListener('mouseleave', this.leaveCard.bind(this))
    })
  }

  moveCard(e) {
    e.preventDefault()

    const { duration, ease } = this.settings

    const card = e.currentTarget
    const elXPos = card.getBoundingClientRect().left
    const elYPos = card.getBoundingClientRect().top
    const width = card.getBoundingClientRect().width
    const height = card.getBoundingClientRect().height

    const elRelativeXPos = e.clientX - elXPos
    const elRelativeYPos = e.clientY - elYPos
    const xPos = ((elRelativeXPos / width) - 0.5) * 2
    const yPos = ((elRelativeYPos / height) - 0.5) * 2

    const rotationXValue = 25 * yPos
    const rotationYValue = -25 * xPos

    const tween = gsap.to(card, { rotateY: rotationYValue, rotateX: rotationXValue, duration, ease })

    this.tweensIn.push(tween)
  }

  leaveCard(e) {
    e.preventDefault()

    const { duration, ease } = this.settings

    const tween = gsap.to(e.currentTarget, { rotateX: 0, rotateY: 0, duration, ease })

    this.tweensOut.push(tween)
  }

  destroy() {
    const { cards } = this.DOM

    if (cards.length) cards.forEach(card => {
      card.removeEventListener('mousemove', this.moveCard.bind(this))
      card.removeEventListener('mouseleave', this.leaveCard.bind(this))
    })

    // if (this.tweensIn.length) this.tweensIn.forEach(tween => tween.kill())
    // if (this.tweensOut.length) this.tweensOut.forEach(tween => tween.kill())
  }
}
