/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageComponents from './pages/components'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'
import PageContact from "./pages/page-contact";
import PageCommitments from "./pages/page-commitments";
import SingleProduct from "./pages/single-product";
import ArchiveProducts from "./pages/archive-products";
import PageFAQ from './pages/page-faq';
import PageHome from './pages/front-page'
import PageBlogs from "./pages/page-blogs";
import PageCommande from "./pages/page-commande";

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links : 'a[data-taxi-transition]',
  removeOldContent: false,
  renderers: {
    default: Page,
    pageContact: PageContact,
    pageComponents: PageComponents,
    pageCommitments: PageCommitments,
    pageFAQ: PageFAQ,
    pageHome: PageHome,
    pageBlogs: PageBlogs,
    singleProduct: SingleProduct,
    archiveProducts: ArchiveProducts,
    pageCommande: PageCommande
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
