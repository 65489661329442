import {Renderer} from "@unseenco/taxi";
import {isDEVMODE, viewStorage} from "../_globals";
import WknSwiper from '../modules/wkn-swiper'
import Form from "../modules/form";

export default class PageBlogs extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      load: viewStorage.current.querySelector('#loadMorePosts'),
      hiddenPosts: viewStorage.current.querySelector('#hiddenPosts'),
      selectCategories: viewStorage.current.querySelectorAll('.selectCategory'),
      selectAuthors: viewStorage.current.querySelectorAll('.selectAuthor'),
      formAuthor: viewStorage.current.querySelector('#formAuthor'),
      otherProducts : viewStorage.current.querySelectorAll('.other-products')
    }
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Blogs`)
    if (isDEVMODE) console.log(viewStorage.current)

    const {load, selectCategories, selectAuthors, formAuthor, otherProducts} = this.DOM

    this.showAllPosts = this.showAllPosts.bind(this)
    this.submitAuthor = this.submitAuthor.bind(this)
    otherProducts && this.initOtherProducts()

    this.formAuthor = new Form(formAuthor)

    if (load) load.addEventListener('click', this.showAllPosts)

    for (let selectCategory of selectCategories) {
      selectCategory.addEventListener('change', this.submitCategory)
    }

    for (let selectAuthor of selectAuthors) {
      selectAuthor.addEventListener('change', this.submitAuthor)
    }
  }

  submitCategory(e) {
    window.location.href = e.target.value
  }

  submitAuthor() {
    const { formAuthor } = this.DOM

    formAuthor.submit()
  }

  showAllPosts(e) {
    const {hiddenPosts, load} = this.DOM

    e.preventDefault()

    hiddenPosts.removeAttribute('hidden')
    hiddenPosts.classList.add('visible')
    load.classList.add('hidden')
  }

  initOtherProducts() {
    const { otherProducts } = this.DOM

    this.otherProductsSwipers = []

    otherProducts.forEach(section => {
      const swiper = new WknSwiper(section.querySelector('.swiper'), {
        slidesPerView: 1,
        spaceBetween: 20,
        centerInsufficientSlides: true,
        breakpoints : {
          768 : {
            slidesPerView: 4,
          }
        }
      })

      this.otherProductsSwipers.push(swiper)
    })
    
  }

  destroy() {
    const {load, selectCategories, selectAuthors} = this.DOM

    if (load) load.removeEventListener('click', this.showAllPosts)
    for (let selectCategory of selectCategories) {
      selectCategory.removeEventListener('change', this.submitCategory)
    }

    for (let selectAuthor of selectAuthors) {
      selectAuthor.removeEventListener('change', this.submitAuthor)
    }

    this.otherProductsSwipers.forEach(swiper => swiper.destroy())
  }
}
