import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class ArchiveProducts extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    this.otherProductsSwiper = new WknSwiper(viewStorage.current.querySelector('.other-products .swiper'), {
      slidesPerView: 1,
      spaceBetween: 20,
      centerInsufficientSlides: true,
      breakpoints : {
        768 : {
          slidesPerView: 4,
        }
      }
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.otherProductsSwiper.destroy()
  }
}
