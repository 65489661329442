import { domStorage, viewStorage } from "../_globals"
import gsap from 'gsap'
import Matter from 'matter-js'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

export default class Footer {
  constructor() {
    this.DOM = { 
      el: domStorage.footer,
      OLF : domStorage.footer.querySelector('.BrandOLF'),
      OLFItems: domStorage.footer.querySelectorAll('.BrandOLF > div'),
      OLFPaths: domStorage.footer.querySelectorAll('.BrandOLF path')
    }
    this.DOM.scrollTop = this.DOM.el.querySelector('.ScrollTop')

    this.init()
  }

  init() {
    const { scrollTop } = this.DOM

    this.scrollToTheTop = this.scrollToTheTop.bind(this)
    scrollTop.addEventListener('click', this.scrollToTheTop)

    const onLoaded = () => {
      window.removeEventListener('load', onLoaded)
      setTimeout(this.initOLF(), 2500)
    }

    window.addEventListener('load', onLoaded)
  }

  initOLF () {
    console.log('initOLF Footer')
    const { Engine, Bodies, Body, Common, Composite, Vertices, Svg } = Matter
    this.engine = Engine.create({ gravity: { y: 1.2 } }),
    this.world = this.engine.world;

    /* this.render = Render.create({
      element: this.DOM.el,
      engine: this.engine,
      options: {
        width: this.DOM.el.offsetWidth,
        height: this.DOM.el.offsetHeight,
        wireframes: false,
        pixelRatio: window.devicePixelRatio,
        background: 'transparent',
      }
    })

    Render.run(this.render) */
    
    this.groundBodies = Composite.create()
    this.olfBodies = Composite.create()

    const createWorld = () => {
      const ground = [
        Bodies.rectangle(this.DOM.el.offsetWidth * 0.5, this.DOM.el.offsetHeight + 49, this.DOM.el.offsetWidth, 100, {isStatic: true}),
        Bodies.rectangle(-49, this.DOM.el.offsetHeight * 0.5, 100, this.DOM.el.offsetHeight, {isStatic: true}),
        Bodies.rectangle(this.DOM.el.offsetWidth + 49, this.DOM.el.offsetHeight * 0.5, 100, this.DOM.el.offsetHeight, {isStatic: true})
      ]

      Composite.add(this.groundBodies, ground, true)
  
      this.DOM.OLFItems.forEach((item, index) => {
        const ratio = Number(item.offsetWidth / item.querySelector('svg').getAttribute("width"))
        const olfPath = Vertices.scale(Svg.pathToVertices(item.querySelector('path')), ratio, ratio)
        const body = Bodies.fromVertices(0, 0, olfPath, {
            restitution: 0.1,
            friction: 0.1
        })
        Body.rotate( body, Common.random(-Math.PI / 2, Math.PI / 2));
        Body.setVelocity( body, { x: 0, y : 20 });
        Composite.add(this.olfBodies, [ body ], true)
      })
    }

    Composite.add(this.world, this.groundBodies)
    Composite.add(this.world, this.olfBodies)
    
    const onRender = (time, deltaTime) => {
      const { OLFItems } = this.DOM
      
      if(this.olfBodies.bodies) {
        this.olfBodies.bodies.forEach((body, index) => {
          const { position, angle:angleRadian } = body
          const angle = angleRadian * (180/Math.PI)
          gsap.set(OLFItems[index], { yPercent: -50, xPercent: -50, y : position.y, x:position.x, rotate: angle })
        })

        Engine.update(this.engine, deltaTime)
      }
    }

    createWorld()


    ScrollTrigger.create({
      trigger: this.DOM.el,
      onEnter: () => {
        let height = 0
        this.olfBodies.bodies.forEach((body, index) => {
          height += body.bounds.max.y - body.bounds.min.y + 10
          Body.setPosition(body, { x : Common.random(0, this.DOM.el.offsetWidth), y : -height })
          Body.setVelocity( body, { x: 0, y : 20 });
        })
        gsap.ticker.add(onRender)
      },
      onLeaveBack: () => {
        gsap.ticker.remove(onRender)
      },
    });


   // Resize
    let resizeTimeout = setTimeout(() => {}, 0)
    let windowWidthResize = window.innerWidth

    const onResize = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        if (windowWidthResize !== window.innerWidth) {
          windowWidthResize = window.innerWidth
          Composite.clear(this.groundBodies)
          Composite.clear(this.olfBodies)
          createWorld()
        }
      }, 250)
    }
    
    window.addEventListener('resize', onResize)

  }

  scrollToTheTop(e) {
    e && e.preventDefault()

    const { hasSmoothScroll, viewScroll } = viewStorage
    if (hasSmoothScroll) viewScroll.scroll.scrollTo(viewStorage.current.children[0], { duration: 1.5, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2 })
    else gsap.to(window, {duration: 1.5, scrollTo: { y: viewStorage.current.children[0], autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }
}