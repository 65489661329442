import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import History from '../modules/history'
import gsap from 'gsap'

export default class PageCommitments extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {

    this.DOM = {
      team : viewStorage.current.querySelector('.teams__block'),
      history : viewStorage.current.querySelector('.history'),
      value : viewStorage.current.querySelector('.values__block'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Commitments`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { team, history, value } = this.DOM
    team && this.initTeam()
    value && this.initValues()
    if(history) this.history = new History(history)

  }

  initValues() {
    const { value } = this.DOM
    let mm = gsap.matchMedia();

    mm.add("(min-width: 768px)", () => {
  
      this.coloredCardsTL = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: { 
          trigger: value,
          scrub: true,
          start: 'top center',
          end: 'bottom center',
          markers: isDEVMODE,
          invalidateOnRefresh: true
        }
      })
      .fromTo(value.querySelectorAll('.card:nth-child(3n + 1)'), { yPercent: 20 }, { yPercent: -30 }, 0)
      .fromTo(value.querySelectorAll('.card:nth-child(3n + 2)'), { yPercent: -20 }, { yPercent: 0 }, 0)
      .fromTo(value.querySelectorAll('.card:nth-child(3n + 3)'), { yPercent: 30 }, { yPercent: -50 }, 0)
    })
  }

  initTeam() {
    const { team } = this.DOM
    this.teamSwiper = new WknSwiper(team.querySelector('.swiper'), { 
      slidesPerView: 1,
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          // will set `translateZ(-400px)` on previous slides
          translate: ['-110%', '7%', 0],
          rotate: [0, 0, -5],
        },
        next: {
          // will set `translateX(100%)` on next slides
          translate: ['110%', '7%', 0],
          rotate: [0, 0, 5],
        },
      },
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { history, teamSwiper } = this

    teamSwiper.destroy()
    history.destroy()
    this.coloredCardsTL && this.coloredCardsTL.kill()
  }
}
