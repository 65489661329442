import { isDEVMODE, globalStorage, domStorage } from '../_globals'
import { Observer } from 'gsap/Observer'


export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.headerContainer = this.DOM.el.querySelector('.container')
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    this.DOM.subMenu = this.DOM.el.querySelector('.Nav__sub')
    this.DOM.subMenuToggle = this.DOM.el.querySelector('.Nav__sub > button')
    this.setEvents()
  }

  setEvents() {
    const { body, overlay } = domStorage
    const { headerContainer, toggle, subMenuToggle } = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()
    globalStorage.closeSubMenu = () => this.closeSubMenu()

    // Submenu
    this.onClickOutside = this.onClickOutside.bind(this)
    subMenuToggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.subMenuOpen === true ? this.closeSubMenu() : this.openSubMenu()
    })

    // overlay
    overlay.addEventListener('click', (e) => {
      if (globalStorage.menuOpen) this.close()
    })

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)

    // Observers
    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 50 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 50 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 50,
      onUp: () => {
        if (!headerContainer.classList.contains('--visible')) headerContainer.classList.add('--visible')
      },
      onDown: () => {
        if (headerContainer.classList.contains('--visible')) headerContainer.classList.remove('--visible')
      }
    })

    this.onHoverObserver = Observer.create({
      target: headerContainer,
      type: 'pointer',
      onHover: () => this.onScrollObserver.disable(),
      onHoverEnd: () => this.onScrollObserver.enable()
    })
  }

  open() {
    // if (isDEVMODE) console.log('Open Menu')

    globalStorage.menuOpen = true
    domStorage.body.classList.add('showMenu')
    domStorage.body.style.overflow = 'hidden'

  }

  close() {
    // if (isDEVMODE) console.log('Close Menu')

    globalStorage.menuOpen = false
    domStorage.body.classList.remove('showMenu')
    domStorage.body.style.overflow = ''
  }

  openSubMenu() {
    const { subMenu } = this.DOM
    globalStorage.subMenuOpen = true
    subMenu.classList.add('--open')
    setTimeout(() => document.addEventListener('click', this.onClickOutside), 150)
  }

  closeSubMenu() {
    const { subMenu } = this.DOM
    globalStorage.subMenuOpen = false
    subMenu.classList.remove('--open')
  }

  onClickOutside(e) {
    const { subMenu } = this.DOM
    if (!subMenu.contains(e.target)) {
      this.closeSubMenu()
      document.removeEventListener('click', this.onClickOutside)
    }
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        // if (isDEVMODE) console.log('updateHeaderResize')
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
