import { isDEVMODE } from '../_globals'
import { gsap } from 'gsap'
import Swiper, { Navigation, Pagination, Scrollbar, EffectCreative } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar, EffectCreative])

export default class History {

  constructor(container) {

    if (!container) return

    this.DOM = { 
      container,
      circle : container.querySelector('.history__circle'),
      links : container.querySelectorAll('.history__dates .link'),
      swiper : container.querySelector('.swiper')
    }

    this.init()
    this.addEvents()
  }

  init() {

    if (isDEVMODE) console.log('Init History')

    const { swiper, circle } = this.DOM

    this.swiper = new Swiper(swiper, {
      speed: 600,
      spaceBetween: 40,
      slidesPerView: 1,
      longSwipesRatio: 0.1,
      navigation: {
        prevEl: swiper.querySelector('.prev'),
        nextEl: swiper.querySelector('.next')
      }
    })

    this.onSlideChange = this.onSlideChange.bind(this)
    this.swiper.on('slideChange', this.onSlideChange)

    // Cicle
    const circleDates = circle.querySelectorAll('.history__circle__inner > div')
    circleDates.forEach((item, idx) => {
      gsap.set(item, { rotate: () => (360 / circleDates.length * idx) })
    })
  }

  addEvents() {
    const { links } = this.DOM
    this.onClickLink = this.onClickLink.bind(this)
    links.forEach(link => link.addEventListener('click', this.onClickLink))

  }

  onClickLink(e) {
    e.preventDefault()
    const { currentTarget } = e
    const { links } = this.DOM
    const index = [...links].indexOf(currentTarget)
    this.swiper.slideTo(index)
  }
  
  onSlideChange(swiper) {
    const { links, circle } = this.DOM
    const currentSlide = swiper.slides[swiper.activeIndex]
    const { date } = currentSlide.dataset
    links[swiper.previousIndex].classList.remove('--active')
    links[swiper.activeIndex].classList.add('--active')

    gsap.to(circle.querySelector('.history__circle__wrapper'), { rotate: '+=60', ease: 'expo.out', duration: 1.2 })
    circle.querySelectorAll('.date textPath').forEach(el => el.textContent = date)
  }

  destroy() {
    this.swiper && this.swiper.destroy()
  }
}