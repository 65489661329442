import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class PageCommande extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)
    this.DOM = {
      differentExpeditionAdressCb: viewStorage.current.querySelector('#ship-to-different-address-checkbox')
    }

    this.onDifferentExpeditionAdressChange = this.onDifferentExpeditionAdressChange.bind(this);
    this.DOM.differentExpeditionAdressCb.addEventListener('change', this.onDifferentExpeditionAdressChange)
    this.onDifferentExpeditionAdressChange()

    this.onExpeditionMethodChange = this.onExpeditionMethodChange.bind(this);
    jQuery(document.body).on('updated_checkout', this.onExpeditionMethodChange)

  }

  onDifferentExpeditionAdressChange(e){
    this.differentExpeditionAdressWasCheckedBeforeLastExpeditionMethodChange = this.DOM.differentExpeditionAdressCb.checked;
  }

  onExpeditionMethodChange(){
    if (this.differentExpeditionAdressWasCheckedBeforeLastExpeditionMethodChange !== undefined){
      this.DOM.differentExpeditionAdressCb.checked = this.differentExpeditionAdressWasCheckedBeforeLastExpeditionMethodChange;
    }
  }

  onLeaveCompleted() {
    jQuery(document.body).off('updated_checkout', this.onExpeditionMethodChange)
  }
}
