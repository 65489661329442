import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { current } = viewStorage

  let pageHeader = null
  if (current.querySelector('.Page__header')) pageHeader = current.querySelector('.Page__header')
  if (current.querySelector('.blog__header')) pageHeader = current.querySelector('.blog__header')
  if (current.querySelector('.Archive__header')) pageHeader = current.querySelector('.Archive__header')
  if (current.querySelector('.ContactForm__title')) pageHeader = current.querySelector('.ContactForm__title')

  const pageTitle = pageHeader ? pageHeader.querySelector('h1') : null
  const pageSubtitle = pageHeader ? pageHeader.querySelector('.subtitle') : null
  const pageIntro = pageHeader ? pageHeader.querySelector('p') : null
  const pageHeaderIcones = pageHeader ? pageHeader.querySelectorAll('.icones > *') : null
  const pageHeaderFlowers = pageHeader ? pageHeader.querySelector('.flowers img') : null
  const pageAnchors = pageHeader ? pageHeader.querySelector('.Anchors__nav') : null

  const pageSections = [...current.children]
  let allSectionsExceptTheFirst = pageSections.slice(1)
  if (current.querySelector('.ContactForm__title')) allSectionsExceptTheFirst = current.querySelector('.ContactForm .Form')
  
  const pageTitleLines = pageTitle ? new SplitText(pageTitle, { type: 'lines' }) : null
  const pageTitleLinesC = pageTitle ? new SplitText(pageTitle, { type: 'lines', linesClass: '--oh' }) : null

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power4.out', duration: 0.8 }
    })
    
    pageSubtitle && tl.fromTo(pageSubtitle, { opacity: 0 }, { opacity: 1, duration: 0.6 }, 0)
    pageTitleLines && pageTitleLines.lines.length && tl.fromTo(pageTitleLines.lines, { yPercent: 102 }, { yPercent: 0, stagger: 0.1 }, pageSubtitle ? '-=1' : 0)
    pageIntro && tl.fromTo(pageIntro, { yPercent: 40, opacity: 0 }, { yPercent: 0, opacity: 1 }, '-=0.8')
    allSectionsExceptTheFirst.length > 0 && tl.fromTo(allSectionsExceptTheFirst, { opacity: 0 }, { opacity: 1 }, '-=0.6')
    pageHeaderIcones && tl.fromTo(pageHeaderIcones, { scale: 0, rotate: (index) => gsap.utils.random(-120, 120, 1)  }, { scale: 1, rotate: 0, stagger: 0.03, ease: 'expo.out', duration : 2.2 }, '-=1.2')
    pageHeaderFlowers && tl.fromTo(pageHeaderFlowers, { yPercent: 100}, { yPercent: 0, ease: 'expo.out', duration : 2.2 }, '-=2')

  return tl
}
