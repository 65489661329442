import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'

export default class PageHome extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
        hero : viewStorage.current.querySelector('.Home__header'),
        otherProducts : viewStorage.current.querySelectorAll('.other-products'),
        cardSection: viewStorage.current.querySelector('.reinsurance__cards'),
        cards: viewStorage.current.querySelector('.reinsurance__cards .colored-cards')
      }

    this.init()
  }

  init() {
    const { hero, cardSection, otherProducts } = this.DOM

    hero && this.initHero()
    otherProducts && this.initOtherProducts()
    cardSection && this.initCards()

  }

  initHero() {
    const { hero } = this.DOM
    const heroTitleLines = hero.querySelectorAll('h1 > span')
    const heroGallery = hero.querySelectorAll('.gallery > .visual__container')

    let mm = gsap.matchMedia();
    mm.add("(min-width: 768px)", () => {
  
      this.heroTL = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: { 
          trigger: hero,
          start: 'top top',
          end: 'bottom center',
          scrub: true,
          markers: isDEVMODE,
          invalidateOnRefresh: true
        }
      })
      .fromTo(heroTitleLines[1], { xPercent: 20 }, { xPercent: -50, duration: 0.5 }, 0)
      .fromTo(heroTitleLines[2], { xPercent: -25 }, { xPercent: 50, duration: 0.5 }, 0)
      .fromTo(heroGallery[0], { scale: 0.5, y: () => -hero.offsetHeight + window.innerHeight * 0.4, x: () => -window.innerWidth* 0.35, rotate: gsap.utils.random(-10, 10, 1) }, { scale: 1, y : 0, x: 0, rotate: -18, ease: 'power2.inOut' }, 0)
      .fromTo(heroGallery[1], { scale: 0.5, y: () => -hero.offsetHeight + window.innerHeight, x: () => -window.innerWidth * 0.3, rotate: gsap.utils.random(-10, 10, 1)}, { scale: 1, y : 0, x: 0, rotate: -6, ease: 'power2.inOut' }, 0)
      .fromTo(heroGallery[2], { scale: 0.65, y: () => -hero.offsetHeight + window.innerHeight * 0.5, x: () => window.innerWidth * 0.4, rotate: gsap.utils.random(-10, 10, 1)}, { scale: 1, y : 0, x: 0, rotate: 4, ease: 'power2.inOut' }, 0)
      
    })
    
  }

  initOtherProducts() {
    const { otherProducts } = this.DOM

    this.otherProductsSwipers = []

    otherProducts.forEach(section => {
      const swiper = new WknSwiper(section.querySelector('.swiper'), {
        slidesPerView: 1,
        spaceBetween: 20,
        centerInsufficientSlides: true,
        breakpoints : {
          768 : {
            slidesPerView: 4,
          }
        }
      })

      this.otherProductsSwipers.push(swiper)
    })
    
  }

  initCards() {
    const { cardSection, cards } = this.DOM

    const cardWrapper = cards.querySelector('.wrapper')
    
    this.cardsTL = gsap
    .timeline({
      defaults: { ease: 'none' },
      scrollTrigger: { 
        trigger: cardSection,
        start: 'top top',
        end: 'bottom center',
        scrub: true,
        pin: cards,
        markers: isDEVMODE,
        invalidateOnRefresh: true
      }
    })
    .fromTo(cardWrapper, { x: () => window.innerWidth * 1.1 }, { x : () => - (cardWrapper.offsetWidth * 1.1), duration: 1.2 }, 0)


    const rotations = [
      { from: -50, to : 20 },
      { from: -30, to : 30 },
      { from: -20, to : 50 },
      { from: 50, to : -20 },
      { from: 30, to : -30 },
      { from: 20, to : -50 }
    ]

    this.cardsTL = gsap
    .timeline({
      defaults: { ease: 'none' },
      scrollTrigger: { 
        trigger: cardSection,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        markers: isDEVMODE,
        invalidateOnRefresh: true
      }
    })

    cardWrapper.querySelectorAll(':scope > *').forEach((card, index) => {
      const random = gsap.utils.random(0, 5, 1)
      this.cardsTL.fromTo(card, { 
        yPercent: () => gsap.utils.random(0, 10, 1),
        xPercent: () => 100 * index,
        rotate: () => rotations[random].from
      }, { 
        yPercent: () => gsap.utils.random(-10, 0, 1),
        xPercent: () => gsap.utils.random(-20, 0, 1),  
        rotate: () => rotations[random].to,
      }, 0)
    })
    
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.heroTL && this.heroTL.kill()
    this.cardsTL && this.cardsTL.kill()
    this.otherProductsSwipers.forEach(swiper => swiper.destroy())
  }
}
